import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import AuthService from "../../services/auth.service";

let data_children = [];
class VerProductList extends Component {

    constructor(props){
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
            total: this.props.product.price,
            price: this.props.product.price,
            size_selected: 0,
            //children: this.props.product.Children
        }
    }

    onClickHandle(img) {
        this.setState({ image : img} );
    }
    onOpenModal = (e) => {
        e.preventDefault();
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if((this.state.quantity - 1) > 0){
            this.setState({quantity: this.state.quantity - 1})
            let total = this.state.price * (this.state.quantity-1);
            this.setState({total: total});
        }
    }

    plusQty = () => {
        this.setState({quantity: this.state.quantity+1})
        let total = this.state.price * (this.state.quantity+1);
        this.setState({total: total});
    }

    changeQty = (e) => {
        if(e.target.value != '')
        {
            this.setState({ quantity: parseInt(e.target.value) })
            let total = this.props.product.price * e.target.value;
            this.setState({total: total});
        }
    }
    
    thisHandleAddCart(product, quantity, redirect){
        if(data_children.length > 0)
        {
            this.props.handlerAddCart(data_children[this.state.size_selected], quantity, redirect);
        }else{
            this.props.handlerAddCart(product, quantity, redirect);
        }
        this.onCloseModal();
    }

    select_item(value){
        this.setState({size_selected: value});
        let price = data_children[value].price;
        this.setState({price: price});
        let quantity = this.state.quantity;
        let total = price * quantity;
        this.setState({total: total});
    }

    handleImageLoaded() {
        document.getElementsByClassName("page-loader")[0].style.display = "none";
    }

    stopLoading() {
        //console.log('loaded!')
        document.getElementsByClassName("page-loader")[0].style.display = "none";
        //this.setState({ imageStatus: "loaded" });
      }

    render() {
        data_children = this.props.product.Children;
        const { 
            product, 
            onAddToCartClicked, 
            BuynowClicked, 
            onAddToWishlistClicked, 
            onAddToCompareClicked, 
            categoria, 
            indice,
            cant_categ,
            color
        } = this.props;

        //console.log('categoria ver-list-product', categoria);
        var colorBoton = categoria.color;
        var color_2 = categoria.color_2?categoria.color_2:'#161616';
        var textColor = categoria.text_color?categoria.text_color:'#161616';
        //console.log('categoria ver-list-product categoria.text_color', categoria.text_color , categoria.text_color?categoria.text_color:'#161616');
        var background_image = `${process.env.REACT_APP_URL_FILES}images/categories/` + categoria.background_url;

        //const symbol = '';
        let RatingStars = []

        const customStyles = {
            backgroundColor: colorBoton,
            color: textColor,
            border: "2px solid "+colorBoton,
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                // const color = chroma(data.color);
                //console.log({ data, isDisabled, isFocused, isSelected });
                return {
                  ...styles,
                  backgroundColor: colorBoton,
                  color: colorBoton
                };
              },
            control: (base, state) => ({
              ...base,
              background: "#212121",
              opacity: 0.5,
              border: "2px solid "+colorBoton,
              //color: "#cfd4da",
              borderRadius: 30,
              backgroundColor: colorBoton,
            }),
            singleValue: (base, state) => ({
                ...base,
                color: colorBoton
            }),
          };

        for(var i = 0; i < product.rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }
        var handleAddCart  =   this.props.handlerAddCart;

        let url_image = `${process.env.REACT_APP_URL_FILES}images/products/`;
        let url_image_grammage = `${process.env.REACT_APP_URL_FILES}images/grammage/`;

        let array_grammage = [];
        array_grammage = product.Grammage_Product;
        let img_grammage1 = '';
        let img_grammage2 = '';
        let img_grammage3 = '';
        if(array_grammage.length > 0)
        {
            for (let index = 0; index < array_grammage.length; index++) {
                const element = array_grammage[index];
                //console.log(element.Grammage);
                if(element.Grammage.type == 'TIPO1')
                {
                    img_grammage1 = element.Grammage.image;
                }else if(element.Grammage.type == 'TIPO2'){
                    img_grammage2 = element.Grammage.image;
                }else{
                    img_grammage3 = element.Grammage.image;
                }
            }
        }
        //var ruta = AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart';
        return (
                <div>
                    <div className="home home-list-products">
                        <div className="container">
                            <div>
                                <div className="row_product row_prods">
                                    <div className="list-product content-product-img background-circle-prod" style={{background: 'linear-gradient(162.68deg, '+colorBoton+' 8.81%, '+color_2+' 89.66%)'}}>
                                        {/* {product.background_url}
                                        {product.image_1?product.image_1:''}
                                        {product.image_2?product.image_2:''}
                                        {product.image_3?product.image_3:''} */}
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/fondo_bolsa.png`} className="img-fluid" alt="" />
                                        {product && product.logo ? <img src={url_image + product.logo} className="img-fluid" onLoad={this.handleImageLoaded.bind(this)}/> :this.stopLoading()}
                                        {img_grammage1 != ''? <img src={url_image_grammage + img_grammage1} className="img-fluid"/> :'' }
                                        {img_grammage2 != ''? <img src={url_image_grammage + img_grammage2} className="img-fluid"/> :'' }
                                        {img_grammage3 != ''? <img src={url_image_grammage + img_grammage3} className="img-fluid"/> :'' }
                                    </div>
                                    <div className="slider-contain side-watch side-prods">
                                        <div className="items-product">
                                            <h1 className="item-prod-name">{product.name}</h1>
                                            <div className="buttons-product">
                                                <a href="#" data-toggle="modal"
                                                data-target="#quick-view"
                                                title="Quick View"
                                                style={customStyles}
                                                onClick={this.onOpenModal} className="btn ver-btn-solid watch-product">Ver +</a>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-index"><label>{indice}</label> / {cant_categ}</div>
                            </div>
                        </div>
                    </div>
                    
                    <Modal open={this.state.open} onClose={this.onCloseModal} center classNames={{
                        closeButton: 'btn-close-modal-product'
                        }}>
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document" style={{marginTop: '4rem'}}>
                            <div className="modal-content quick-view-modal modal-product">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-lg-6  col-xs-12">
                                            <div className="quick-view-img list-product content-product-img background-circle-prod modal-background-prod" style={{background: 'linear-gradient(162.68deg, '+colorBoton+' 8.81%, '+color_2+' 89.66%)'}}>
                                                {product && product.image_4 ? <img src={url_image + product.image_4} className="img-fluid"/> :'' }
                                                {product && product.logo ? <img src={url_image + product.logo} className="img-fluid"/> :'' }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 rtl-text">
                                            <div className="product-right">
                                                <div className="product-description border-product">
                                                    <div className="details-prop">
                                                        <div className="row props ver-detail-props">
                                                            <div className="col-md-2  product-info ver-product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_aroma.png`} alt="" />
                                                            </div>
                                                            <div className="col-md-8 ver-product-data">
                                                                <label style={customStyles}>AROMA</label>
                                                                <span>{product.smell}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row props ver-detail-props">
                                                            <div className="col-md-2  product-info ver-product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_puesta.png`} alt="" />
                                                            </div>
                                                            <div className="col-md-8 ver-product-data">
                                                                <label style={customStyles}>PUESTA</label>
                                                                <span>{product.puesta}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row props ver-detail-props">
                                                            <div className="col-md-2  product-info ver-product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_dato.png`} alt="" />
                                                            </div>
                                                            <div className="col-md-8 ver-product-data">
                                                                <label style={customStyles}>DATO</label>
                                                                <span>{product.info}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                    data_children.length > 0?
                                                    <div className="ver-prod-size">
                                                        { data_children.map((item, index ) =>
                                                                <div key={index} className={`ver-size ${this.state.size_selected==index?'size-selected' : 'size-unselected'}`} onClick={() => this.select_item(index)}>
                                                                    {index==0?<img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-size.png`} alt="" style={{width: '18%', margin: '0 auto', marginTop: '5px', marginBottom: '5px'}}/>:<img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-size-2.png`} alt="" style={{width: '45%', margin: '0 auto', marginTop: '5px', marginBottom: '5px'}}/>}
                                                                    <span>{item.size}</span>
                                                                    <hr style={{color: 'rgb(248, 238, 225)',backgroundColor: 'rgb(248, 238, 225)',height: '2px', width: '100%', marginTop: '5px', marginBottom: '10px'}}></hr>
                                                                    <label><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px'}}/> {(parseFloat(item.price)).toFixed(0)} </label>
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    </div>:''
                                                    }
                                                    <h6 className="product-title-2">CANTIDAD</h6>
                                                    <div className="qty-box">
                                                        <div className="input-group">
                                                              <span className="input-group-prepend span-btn-left">
                                                                <button type="button" className="btn quantity-left-minus modal-btn-number modal-btn-number-left" onClick={this.minusQty} data-type="minus" data-field="">
                                                                <i className="fa fa-minus"></i>
                                                                </button>
                                                              </span>
                                                            <input type="text" name="quantity" value={this.state.quantity}  onChange={this.changeQty} className="form-control input-number" />
                                                                <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-right-plus modal-btn-number modal-btn-number-right" onClick={this.plusQty} data-type="plus" data-field="">
                                                                <i className="fa fa-plus"></i>
                                                                </button>
                                                               </span>
                                                        </div>
                                                    </div>
                                                    <div className="detail-total">
                                                        <div>
                                                        TOTAL:
                                                        <label style={{marginLeft: '10px'}}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px'}}/> {(parseFloat(this.state.total)).toFixed(0)} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                        <div className="row check-out row-check-date " style={{textAlign: 'center'}}>
                                                            <div className="form-group col-md-6 col-sm-6 col-xs-6 profile-buttons">
                                                                
                                                                <span className="ver-btn-solid btn btn-add-prod btn-primary"
                                                                style={{
                                                                    backgroundColor: colorBoton,
                                                                    border: '2px solid ' + colorBoton,
                                                                    color: textColor }}
                                                                onClick={() => this.thisHandleAddCart(product, this.state.quantity , null)} >AGREGAR AL CARRITO</span> 
                                                            </div>
                                                        </div>
                                                        <div className="product-buttons">
                                                            
                                                            <span style={customStyles} 
                                                            className="btn ver-btn-solid btn-purchase product-btn-purchase"
                                                            onClick={() => this.thisHandleAddCart(product, this.state.quantity, 1 )}
                                                            >
                                                              PAGAR</span>


                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
        )
    }
}

export default VerProductList;