import React, { Component } from 'react';
import './cssgrid.scss';

class CategGridProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
            total: (this.props.products[0] && this.props.products[0].price) || 0
        };
    }

    handleImageLoaded() {
        document.getElementsByClassName("page-loader")[0].style.display = "none";
    }

    stopLoading() {
        document.getElementsByClassName("page-loader")[0].style.display = "none";
    }


    changeQty = (e) => {
        if(e.target.value != '')
        {
            this.setState({ quantity: parseInt(e.target.value) })
            let total = this.props.product.price * e.target.value;
            this.setState({total: total});
        }
    }

    getProductImage = (product) => {
        // Definir el orden de prioridad de las imágenes
        const images = ['url','image_4', 'image_1', 'image_2', 'image_3', 'logo'];
    
        // Buscar la primera imagen disponible (que no sea NULL ni undefined)
        for (let image of images) {
            if (product[image] && product[image] !== null && product[image] !== undefined) {
                return product[image]; // Retorna la imagen que esté disponible
            }
        }
    
        // Si ninguna imagen está disponible, retorna una imagen por defecto
        return null;
    };
    
    

    render() {
        const { products, categoria, handlerAddCart, symbol } = this.props;  
        const colorBoton = categoria.color;
        const textColor = categoria.text_color || '#161616';
        const url_image = `${process.env.REACT_APP_URL_FILES}images/products/`;

        // Dividir los productos en grupos de dos
        const groupedProducts = [];
        for (let i = 0; i < products.length; i += 2) {
            groupedProducts.push(products.slice(i, i + 2)); // Toma de a 2 productos
        }

        return (
            <div className="container">
                {/* Agrupar productos en contenedores */}
                <div className="products-grid">
                    {/* Iterar sobre los grupos de productos */}
                    {groupedProducts.map((group, groupIndex) => (
                        <div key={groupIndex} className="product-group">
                            {/* Iterar sobre cada producto dentro del grupo */}
                            {group.map((product, index) => (
                                <div
                                    key={index}
                                    className="product-item"
                                    style={{
                                        border: `2px solid ${colorBoton}`,
                                        borderRadius: '10px',
                                        padding: '16px',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        maxWidth: '200px',
                                    }}
                                >
                                    {product && (this.getProductImage(product)) ? (
                                        <div
                                            className="img-container"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                overflow: 'hidden',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <img
                                                src={url_image + this.getProductImage(product)}
                                                alt={product.name}
                                                className="img-fluid"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                }}
                                                onLoad={this.handleImageLoaded}
                                            />
                                        </div>
                                    ) : (
                                        this.stopLoading() // Manejo de carga si no hay imagen disponible
                                    )}

                                    <h5 className="mt-3" style={{ color: textColor, textAlign: 'center' }}>{product.name}</h5>
                                    
                                    <div className="detail-total">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {product.old_price && product.old_price > 0 && (
                                            <label style={{ marginLeft: '10px', textDecoration: 'line-through', display: 'flex', alignItems: 'center' }}>
                                                <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
                                                alt=""
                                                className="img-fluid"
                                                style={{ marginRight: '5px' }}
                                                />
                                                {(parseFloat(product.old_price * this.state.quantity)).toFixed(0)}
                                            </label>
                                            )}

                                            <label style={{ display: 'flex', marginLeft: '10px', alignItems: 'center' }}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
                                                alt=""
                                                className="img-fluid"
                                                style={{ marginRight: '5px' }}
                                            />
                                            {parseFloat(product.price * this.state.quantity).toFixed(0)}
                                            </label>

                                            
                                        </div>
                                    </div>

                                    <button
                                        className="btn btn-primary mt-2"
                                        style={{
                                            backgroundColor: colorBoton,
                                            border: `2px solid ${colorBoton}`,
                                            color: textColor,
                                            padding: '8px 16px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handlerAddCart(product, this.state.quantity, null)}
                                    >
                                        AGREGAR
                                    </button>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default CategGridProductList;
