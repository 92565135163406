import React, {Component} from 'react';

class VerPageNotFound extends Component {

    constructor (props) {
        super (props)

    }

    componentWillMount() {
        document.body.style = `background-color: #161616;font-family: 'Work Sans'`;
    }

    render (){

        return (
            <div>
                {/*<Breadcrumb title={'404 Page'}/>*/}

                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section">
                                    <h1>404</h1>
                                    <h2>Página no encontrada</h2>
                                    <div className="row-check-date">
                                        <div className="form-group">
                                            <a href="/" className="ver-btn-solid btn btn-save-address btn-primary">Ir al inicio</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default VerPageNotFound