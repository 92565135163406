import React, { useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

// Estilos para el área de dropzone
const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

// Componente UploadVoucher
const UploadVoucher = ({ showOverlay, setShowOverlay, handleVoucherSubmit }) => {
  console.log("Desde el componentne: ",showOverlay);
  
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    onDrop: (files) => {
      console.log("Archivos aceptados:", files); 
      handleVoucherSubmit(files);
    },
    accept: 'application/jpg',
  });

  
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    if (!showOverlay) {
      acceptedFiles.length = 0;
    }
  }, [showOverlay]);

  return (
    <div className="cart-container">
      
      {showOverlay === 'block' && (
        <div id="debt" className="search-overlay has-debt"
          style={{ display: showOverlay }}>
          <div>
            <span
              className="closebtn"
              onClick={() => setShowOverlay(false)}
              title="Cerrar Overlay"
            >
              ×
            </span>
            <div className="overlay-content options-overlay-content">
              <div className="container">
                <div className="row" style={{ display: 'block' }}>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p style={{ color: '#000' }}>
                      Arrastra y suelta algunos archivos aquí, o haz clic para seleccionarlos.
                    </p>
                  </div>
                  <aside>
                    <h4>Archivos</h4>
                    <ul>{files}</ul>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadVoucher;
