import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual';

import HeaderLogin from './common/headers/header-login';
import HeaderWelcome from './common/headers/header-welcome';
import VerPageNotFound from './pages/ver-404';
import PageLoader from './layouts/loader/main';

class App extends Component {
  // Listado de rutas con encabezado específico
  headerWelcomeRoutes = [
    '/cart',
    '/referred-orders',
    '/referred-checkout',
    '/user-checkout',
    '/register-referred',
    '/register-address',
    '/profile',
    '/referreds',
    '/thanks',
    '/debt',
    '/closed'
  ];

  // Función para determinar si una ruta es válida
  isValidRoute() {
    const { pathname } = this.props.location;

    // Verificar rutas exactas
    if (
      [
        '/login',
        '/',
        '/welcome',
        '/unauthorized',
        ...this.headerWelcomeRoutes,
      ].includes(pathname)
    ) {
      return true;
    }

    // Verificar prefijos de rutas válidas
    const validPrefixes = [
      '/products/',
      '/categProducts/',
      '/accesories/',
      '/more-products',
    ];
    return validPrefixes.some((prefix) => pathname.startsWith(prefix));
  }

  // Función para determinar el encabezado según la ruta actual
  getHeaderComponent() {
    const { pathname } = this.props.location;

    if (this.headerWelcomeRoutes.includes(pathname)) {
      return (
        <HeaderWelcome
          logoName="ver_logo.png"
          showNav={true}
          changeBackgroundColor={false}
          homeView={true}
          changeClass="header_cart"
        />
      );
    }

    if (pathname.startsWith('/products/')) {
      return (
        <HeaderWelcome
          logoName="ver_logo2.png"
          showNav={true}
          changeBackgroundColor={true}
          changeClass="header_products"
        />
      );
    }

    if (pathname.startsWith('/categProducts/')) {
      return (
        <HeaderWelcome
          logoName="ver_logo.png"
          showNav={true}
          transparentBackground={true}
          changeBackgroundColor={false}
          homeView={true}
          changeClass="transparent_header"
        />
      );
    }

    if (pathname.startsWith('/more-products')) {
      return (
        <HeaderWelcome
          logoName="ver_logo.png"
          showNav={true}
          transparentBackground={true}
          changeBackgroundColor={false}
          homeView={true}
          changeClass="transparent_header"
        />
      );
    }

    if (pathname.startsWith('/accesories/')) {
      return (
        <HeaderWelcome
          logoName="ver_logo.png"
          showNav={true}
          transparentBackground={true}
          homeView={true}
          changeClass="transparent_header"
        />
      );
    }

    if (!['/login', '/', '/welcome', '/unauthorized'].includes(pathname)) {
      return <HeaderWelcome logoName="ver_logo.png" showNav={true} />;
    }

    return <HeaderLogin />;
  }

  render() {
    const isValid = this.isValidRoute();

    // Si la ruta no es válida, renderizar directamente la página 404
    if (!isValid) {
      return <VerPageNotFound />;
    }

    // Si la ruta es válida, renderizar el resto de la aplicación
    return (
      <div>
        <PageLoader />
        {this.getHeaderComponent()}
        {this.props.children}
      </div>
    );
  }
}

export default withTranslate(App);
