import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import '../../common/index.scss';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import ThemeSettings from '../../common/theme-settings';

// Import custom components
import {
  svgFreeShipping,
  svgservice,
  svgoffer,
  svgpayment,
} from '../../../services/script';
import TopCollection from '../common/collection';
import NewProduct from '../../common/new-product';
import Instagram from '../common/instagram';
import HeaderOne from '../../common/headers/header-one';
//import FooterOne from "../../common/footers/footer-one"
import Categorias from '../common/categorias';
import BlogSection from '../common/blogsection';
import { getBestSeller } from '../../../services';
import ProductItem from '../common/product-item';
import VerProductList from '../common/ver-list-product';
import ProductList from '../common/ver-list-product';
import navbar from '../../common/headers/common/navbar';
import AuthService from '../../services/auth.service';
import ApiService from '../../services/api.service';
import store from '../../../store';
import { addToCart } from '../../../actions';
import { getFromPurchaseRefered, getFromCartUser } from '../../../actions';
import Swal from 'sweetalert2';

let data_categ = [];
let id_categ_selected = 0;
//console.log('products AuthService.getCurrentUser()', AuthService.getCurrentUser());
//var ruta = AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart';

class Products extends Component {
  constructor(props) {
    super(props);
    this.handlerAddCart = this.handlerAddCart.bind(this);
    this.state = {
      open: false,
      results_categ: [],
      results_product: [],
      dataCategoria: {},
    };
  }

  componentWillMount() {
    var user = AuthService.getCurrentUser();
    if (user) {
      document.body.style = `background-color: #F8EEE1;font-family: 'Work Sans'`;
      id_categ_selected = 0;
      this.checkOpen();
    } else {
      this.props.history.push('/login');
    }
  }

  checkOpen() {
    var user = AuthService.getCurrentUser();
    ApiService.checkOpen(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('res.data',res.data);
          if (res.data.option_value == 'NO') {
            if (window.location.pathname != '/closed') {
              this.props.history.push('/closed');
            }
          } else {
            this.getDataCateg();
            this.getRefOrders();
          }
        } else {
          this.props.history.push('/closed');
          window.location.reload();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handlerAddCart(item, quantity, redirect) {
    //console.log(item)
    //console.log(quantity)
    if (item && quantity > 0) {
      var user = AuthService.getCurrentUser();
      //console.log(user)
      //console.log(item)
      //console.log(quantity)
      let formdata = new FormData();
      let data_cart = {
        token: user.token_order,
        id_user: user.id,
        id_product: item.id,
        quantity: quantity,
        price: item.price,
      };
      ApiService.postItemToCart(user.token, data_cart).then(
        (res) => {
          if (res && res.status == 'success') {
            //this.props.history.push("/"+(AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart'));
            store.dispatch(addToCart(res.data, quantity));

            document.getElementById('addCart-overlay').style.display = 'block';
            setTimeout(this.openAddCartCorrectly(redirect, this.props), 1000);

            //window.location.reload();
            //this.setState({ results_categ:res.data });
            //console.log(this.state.results_categ)
          } else {
            if (res.mensajeError) {
              Swal.fire({
                showConfirmButton: false,
                type: 'warning',
                title: 'Aviso!',
                html: res.mensajeError,
              });
            } else if (res.message) {
              Swal.fire({
                showConfirmButton: false,
                type: 'warning',
                title: 'Aviso!',
                html: res.message,
              });
            }
          }
          /*const items = this.showStatus(res.data).map(function(row) {
                        return { Imagen: row.background_url, id: row.id, Nombre : row.name, Color : row.color, Estado : row.estado, status : row.status }
                     })*/
          /*const items = res.data.map(function(row) {
                        return { id: row.id, Nombre : row.name, Color : row.color, Estado : row.status }
                     })*/
          //this.renderCategResults();
        },
        (error) => {
          this.setState({ results_categ: [] });
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  }

  getDataCateg() {
    var user = AuthService.getCurrentUser();
    let categoria_id = this.props.match.params.id
      ? this.props.match.params.id
      : 0;
    ApiService.getCategParents(user.token, categoria_id).then(
      (res) => {
        if (res && res.status == 'success') {
          data_categ = res.data;
          for (let i = 0; i < data_categ.length; i++) {
            if (i == 0) {
              data_categ[i]['selected'] = 1;
            } else {
              data_categ[i]['selected'] = 0;
            }
          }

          this.setState({ results_categ: data_categ });
          this.getProductByClick(null);
          
        }
     
      },
      (error) => {
        this.setState({ results_categ: [] });
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getRefOrders() {
    var user = AuthService.getCurrentUser();
    ApiService.getReferredOrders(user.token, user.token_order, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          store.dispatch(getFromPurchaseRefered(res.data));
          store.dispatch(getFromCartUser(res.carts_data));
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  showImage(results) {
    // Aplicamos un fix si no devuelve una imagen
    let url_image = `${process.env.REACT_APP_URL_FILES}images/products/`;
    for (let i = 0; i < results.length; i++) {
      results[i]['background_url'] = (
        <img src={url_image + results[i]['url']} className="img-fluid" />
      );
      results[i]['image_1'] = results[i]['image_1'] ? (
        <img src={url_image + results[i]['image_1']} className="img-fluid" />
      ) : (
        ''
      );
      results[i]['image_2'] = results[i]['image_2'] ? (
        <img src={url_image + results[i]['image_2']} className="img-fluid" />
      ) : (
        ''
      );
      results[i]['image_3'] = results[i]['image_3'] ? (
        <img src={url_image + results[i]['image_3']} className="img-fluid" />
      ) : (
        ''
      );
      results[i]['image_4'] = results[i]['image_4'] ? (
        <img src={url_image + results[i]['image_4']} className="img-fluid" />
      ) : (
        ''
      );
      results[i]['logo'] = results[i]['logo'] ? (
        <img src={url_image + results[i]['logo']} className="img-fluid" />
      ) : (
        ''
      );
    }
    return results;
  }

  getProductByClick(categoria) {
    if (categoria) {
      //console.log('if1');
      for (let i = 0; i < data_categ.length; i++) {
        data_categ[i]['selected'] = 0;
      }
      let index = data_categ.findIndex((x) => x.id === categoria.id);
      data_categ[index].selected = 1;
      this.setState({ results_categ: data_categ });
      this.state.dataCategoria = categoria;
    } else {
      //console.log('if2');
      this.state.dataCategoria = this.state.results_categ[0];
    }

    if (this.state.dataCategoria) {
      this.getDataProduct(this.state.dataCategoria.id);
    }
  }

  getDataProduct(id_category) {
    var user = AuthService.getCurrentUser();
    if (id_category != id_categ_selected) {
      document.getElementsByClassName('page-loader')[0].style.display = 'flex';
    }
    ApiService.getProducts(user.token, id_category).then(
      (res) => {
        if (res && res.status == 'success') {

          id_categ_selected = id_category;
          //Asignar el precio de la primera presentacion del producto
          let array_prod = res && res.data ? res.data : [];
          if (array_prod.length > 0) {
            for (let i = 0; i < array_prod.length; i++) {
              const element = array_prod[i];
              //Si no tiene precio por deafult 0
              if (!element.price) {
                array_prod[i]['price'] = 0;
              }
              //Se asigna el precio de la primera presentacion
              let child_prod = element.Children;
              if (child_prod.length > 0) {
                array_prod[i]['price'] = child_prod[0]['price'];
              }
            }
          }
          this.setState({ results_product: array_prod });

          document.getElementsByClassName('page-loader')[0].style.display =
            'none';

        } else {
          document.getElementsByClassName('page-loader')[0].style.display =
            'none';
        }
      },
      (error) => {
        document.getElementsByClassName('page-loader')[0].style.display =
          'none';
        this.setState({ results_product: [] });
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    document
      .getElementById('color')
      .setAttribute('href', `${process.env.PUBLIC_URL}/assets/css/color3.css`);
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: 'InStock' });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: 'Out of Stock !' });
    }
  };
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  openAddCartCorrectly(redirect, propsRuta) {
    setTimeout(function() {
      try {
        document.getElementById('addCart-overlay').style.display = 'none';
      } catch (error) {}
      if (redirect) {
        propsRuta.history.push(
          AuthService.getCurrentUser() && AuthService.getCurrentUser().role
            ? AuthService.getCurrentUser().role == 'REFERIDO'
              ? '/cart'
              : '/user-checkout'
            : '/cart'
        );
      }
    }, 1500);
  }

  closeAddCartCorrectly() {
    document.getElementById('addCart-overlay').style.display = 'none';
  }

  render() {
    const {
      items,
      symbol,
      addToCart,
      addToWishlist,
      addToCompare,
    } = this.props;
    document.body.classList.remove('dark');
    return (
      <div>
        <Helmet>
          <title>Products</title>
        </Helmet>
        {/*<Categorias/>*/}
        <div className="section-categories" id="section-categories">
          <div className="container-fluid' buttons-categ">
            <div className="row row_categ">
              {this.state.results_categ.map((item, index) => (
                <Link
                  to={`#`}
                  onClick={() => this.getProductByClick(item)}
                  className={`col-md-1 p-0 categories_products bookmark_top ${
                    item.selected == 1
                      ? 'btn-selected'
                      : 'btn-opacity btn-inactive'
                  }`}
                  key={index}
                >
                  <div
                    className="categ-image"
                    style={{ color: `${item.title_color}` }}
                  >
                    <img
                      src={
                        `${process.env.REACT_APP_URL_FILES}images/categories/` +
                        item.background_url
                      }
                      className="img-fluid"
                      style={{
                        margin: '0 auto',
                        marginBottom: '0.5rem',
                        display: `${item.selected == 1 ? 'block' : 'none'}`,
                      }}
                    ></img>
                    {item.name}
                  </div>
                </Link>
              ))}
            
            </div>
          </div>
        </div>
        {/*<HeaderOne logoName={'layout3/logo.png'}/>*/}
        <section className="p-0 section_list_products">
          <Slider className="slide-1 home-slider">
            {this.state.results_product.map((product, index) => (
              <div key={index} className="">
                <VerProductList
                  product={product}
                  symbol={symbol}
                  categoria={this.state.dataCategoria}
                  handlerAddCart={this.handlerAddCart}
                  onAddToCompareClicked={() => addToCompare(product)}
                  onAddToWishlistClicked={() => addToWishlist(product)}
                  onAddToCartClicked={() => addToCart(product, 1)}
                  key={'prod' + product.id_category + index}
                  indice={index + 1}
                  cant_categ={this.state.results_product.length}
                />
                
              </div>
            ))}
            
          </Slider>
        </section>

        <div id="addCart-overlay" className="ver-modal-overlay addCart-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeAddCartCorrectly}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content options-overlay-content">
              <div className="container">

                <div style={{ marginBottom: '2.5rem' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/add_message.png`}
                    className="img-fluid"
                    alt=""
                    style={{ display: 'block', margin: '0 auto' }}
                  />
                </div>
                <div>
                  <label className="logout-text1">
                    SE AGREGÓ EL PRODUCTO AL CARRITO
                  </label>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    items: getBestSeller(state.data.products),
    symbol: state.data.symbol,
  };
}

export default connect(mapStateToProps, { addToCart })(Products);
