import React, { Component } from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

import {addToCart} from '../../../actions'
import {getBestSeller} from "../../../services";

import AuthService from "../../services/auth.service";
import ApiService from "../../services/api.service";
import { getFromPurchaseRefered,getFromCartUser} from '../../../actions'
import store from '../../../store';
import Swal from 'sweetalert2';
import CategGridProductList from '../common/list-grid-product';

let data_categ = [];
let id_categ_selected = 0;
class MoreProducts extends Component {

    constructor(props){
        super(props);
        this.handlerAddCart = this.handlerAddCart.bind(this);
        this.state = {
            open: false,
            results_categ: [],
            results_product: [],
            dataCategoria: {},
            isLoading: false, 
        }
    }

    componentWillMount() {
        //console.log(this.props.match.params.id);
        var user = AuthService.getCurrentUser();
        if(user)
        {
            id_categ_selected = 0;
            document.body.classList.add('dark');
            document.body.style = `background-color: #161616;font-family: 'Work Sans'`;
            this.checkOpen();
        }else{
            this.props.history.push("/login");
        }
    }

    checkOpen(){
        var user = AuthService.getCurrentUser();
        localStorage.setItem('hasCheckedOpen', JSON.stringify(true));
        ApiService.checkOpen(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('res.data',res.data);
					if(res.data.option_value == 'NO')
					{
						if(window.location.pathname != '/closed')
						{
							this.props.history.push("/closed")
						}
					}else{
                        this.getDataCateg();
                        //this.getDataProduct(this.props.match.params.id);
                        this.getRefOrders();
                    }
                }else{
					this.props.history.push("/closed")
            		window.location.reload();
				}
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handlerAddCart(item,quantity, redirect) {
        if(item && quantity > 0){
            var user = AuthService.getCurrentUser();

            let formdata = new FormData()
            let data_cart = {
                'token': user.token_order,
                'id_user': user.id,
                'id_product': item.id,
                'quantity': quantity,
                'price': item.price,
            }
            ApiService.postItemToCart(user.token,data_cart).then(
                (res) => {
                    if(res && res.status == 'success'){
                        
                        store.dispatch(addToCart(res.data, quantity))

                        
                        document.getElementById("addCart-overlay").style.display = "block";
                        setTimeout(this.openAddCartCorrectly(redirect, this.props), 1000);

                    }else{
                        if(res.mensajeError)
                        {
                            Swal.fire({
                                showConfirmButton: false,
                                type: 'warning',
                                title: 'Aviso!',
                                html: res.mensajeError,
                            });
                        }else if(res.message){
                            Swal.fire({
                                showConfirmButton: false,
                                type: 'warning',
                                title: 'Aviso!',
                                html: res.message,
                            });
                        }
                    }

                },
                error => {
                  this.setState({ results_categ:[] });
                  const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
        }
    }

    getDataCateg(){
        var user = AuthService.getCurrentUser();
        let categoria_id = this.props.match.params.id ? this.props.match.params.id : 30;
        //ApiService.getCategories(user.token).then(
        ApiService.getCategParents(user.token, categoria_id).then(
            (res) => {
                if(res && res.status == 'success'){
                    data_categ = res.data;
                    for (let i = 0; i < data_categ.length; i++) {
                        if(i==0)
                        {
                            data_categ[i]['selected'] = 1;
                        }else{
                            data_categ[i]['selected'] = 0;
                        }
                    }

                    this.setState({ results_categ:data_categ });
                    if(data_categ.length > 0)
                    {
                        this.getDataProduct(data_categ[0].id);
                    }
                    this.getProductByClick(null);
                    
                    
                }

            },
            error => {
              this.setState({ results_categ:[] });
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    showImage(results){
        // Aplicamos un fix si no devuelve una imagen 
        let url_image = `${process.env.REACT_APP_URL_FILES}images/products/`;
        for (let i = 0; i < results.length; i++){
                results[i]['background_url'] = <img src={url_image + results[i]['url']} className="img-fluid"/>;
        }
        return results;
    }

    getProductByClick(categoria) {
        if (categoria) {
            for (let i = 0; i < data_categ.length; i++) {
                data_categ[i]['selected'] = 0;
            }
            let index = data_categ.findIndex(x => x.id === categoria.id);
            data_categ[index].selected = 1;
            this.state.dataCategoria = categoria;
        } else {
            this.state.dataCategoria = this.state.results_categ[0];
        }

        if (this.state.dataCategoria) {
            this.getDataProduct(this.state.dataCategoria.id);
        }
    }

    getDataProduct(id_category) {
        var user = AuthService.getCurrentUser();

        if (id_category !== id_categ_selected && !this.state.isLoading) {
            document.getElementsByClassName("page-loader")[0].style.display = "flex";
            this.setState({ isLoading: true }); // Set loading state to true

            ApiService.getMoreProducts(user.token).then(
                (res) => {
                    if (res && res.status === 'success') {
                        id_categ_selected = id_category;
                        this.setState({ results_product: res.data });
                        this.renderResults();

                        if (res.data.length === 0) {
                            document.getElementsByClassName("page-loader")[0].style.display = "none";
                        }
                    } else {
                        document.getElementsByClassName("page-loader")[0].style.display = "none";
                    }
                },
                error => {
                    document.getElementsByClassName("page-loader")[0].style.display = "none";
                    this.setState({ results_product: [], isLoading: false }); // Reset loading state on error
                }
            );
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color3.css` );
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onClickHandle(img) {
        this.setState({ image : img} );
    }

    minusQty = () => {
        if(this.state.quantity > 1) {
            this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        if(this.props.product.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity+1})
        }else{
            this.setState({stock: 'Out of Stock !'})
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }
    
    getRefOrders(){
        var user = AuthService.getCurrentUser();
        ApiService.getReferredOrders(user.token,user.token_order,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('data',res.data);

                    store.dispatch(getFromPurchaseRefered(res.data))
                    store.dispatch(getFromCartUser(res.carts_data))

                    //this.setState({ order_results:res.data });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    openAddCartCorrectly(redirect, propsRuta) {

        setTimeout(function() { 
            try {
                document.getElementById("addCart-overlay").style.display = "none"; 
            } catch (error) {
                
            }
            if(redirect){
                //console.log('redirect if', redirect);
                propsRuta.history.push( (AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? '/cart' : '/user-checkout') : '/cart') )
            }
        }, 1500);
    }
	
	closeAddCartCorrectly() {
		document.getElementById("addCart-overlay").style.display = "none";
		
	}

    scrollWin() {
        window.scrollTo(500, 0);
    }

    renderResults() {
        const { items, symbol, addToCart, addToWishlist, addToCompare } = this.props;
        
        // Agrupar los productos de dos en dos
        const groupedProducts = [];
        for (let i = 0; i < this.state.results_product.length; i += 2) {
            groupedProducts.push(this.state.results_product.slice(i, i + 2));
        }
    
        return (
            <>
                <section className="p-0 section_list_categ_products">
                    <label className="categ-product-name-title">¿Un último antojo a precio fumón?</label>
                    {groupedProducts.map((productGroup, index) => (
                        <div key={index}>
                            <CategGridProductList
                                products={productGroup}
                                symbol={symbol}
                                categoria={this.state.dataCategoria}
                                handlerAddCart={this.handlerAddCart}
                                onAddToCompareClicked={(product) => addToCompare(product)}
                                onAddToWishlistClicked={(product) => addToWishlist(product)}
                                onAddToCartClicked={(product, quantity) => addToCart(product, quantity)}
                                key={index}
                                indice={index + 1}
                                cant_categ={this.state.results_product.length}
                            />
                        </div>
                    ))}
                </section>
            </>
        );
    }
    

    myArrow({ type, onClick, isEdge }) {
       
        return (
            <div className="p-0 btn-categ-prod">
                
                <Link to={`#`} className={isEdge == false ? "btn btn-scroll" : "btn btn-scroll visible"} onClick={onClick} disabled={isEdge}>...</Link>      
            </div>
        )
        
      }

    render(){
        //document.body.style.backgroundColor = "#F8ECCB";
        const {items, symbol, addToCart, addToWishlist, addToCompare} = this.props;
        return (
            <div>
                <Helmet>
                    <title>Products</title>
                </Helmet>
                <div className="section-categories">
                <div className="container-section-categ">
                    <div className="row row-section-categ">
                        <div className="styling-example">  
                        
                  
                        </div>
                    </div>
                    </div>
                </div>
                {this.renderResults()}



                <div id="addCart-overlay" className="ver-modal-overlay addCart-overlay">
                        <div>
                            <span className="closebtn" onClick={this.closeAddCartCorrectly} title="Close Overlay">×</span>
                            <div className="overlay-content options-overlay-content">
                                <div className="container">
                                   
                                    <div style={{marginBottom: '2.5rem'}}>
									<img src={`${process.env.PUBLIC_URL}/assets/images/icon/add_message.png`} className="img-fluid" alt="" style={{display: 'block',margin: '0 auto'}}/>
								    </div>
                                    <div>
                                        <label className="logout-text1">SE AGREGÓ EL PRODUCTO AL CARRITO</label>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps,{addToCart}) (MoreProducts);