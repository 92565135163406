import axios from 'axios';

axios.interceptors.response.use(
  (response) => {
    //console.log('interceptando', response);

    return response;
  },
  (error) => {
    //console.log('error => ', error.response.status);
    if (error.response.status == 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
  }
);

const API_URL = process.env.REACT_APP_URL + 'api/';
class ApiService {
  getCategories(access_token, template) {
    return axios
      .get(API_URL + 'categories/template/' + template, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getProducts(access_token, id_category) {
    return axios
      .get(API_URL + 'products/categories/' + id_category, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getProductDetails(access_token, id) {
    return axios
      .get(API_URL + 'products/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postItemToCart(access_token, data) {
    return axios
      .post(API_URL + 'cart', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        /*var cartData = JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')): [];
        //console.log(cartData);
        var dataArray = []; 
        
        if(cartData){
          //console.log('1');
          cartData.push(response.data.data);
        }else{
          //console.log('2');
          dataArray[0] = response.data.data;
          cartData = dataArray;
        }*/

        //localStorage.setItem("cart", JSON.stringify(cartData));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCartUser(access_token, id_user) {
    return axios
      .get(API_URL + 'cart/' + id_user, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        let userData = JSON.parse(localStorage.getItem('user'));
        //console.log('localstorage user original',JSON.parse(localStorage.getItem('user')));
        //console.log('localstorage user',userData);
        //console.log('response.data',response.data,response.data.data)
        userData.token_order = response.data.token_order;
        //console.log('localstorage user2',userData);
        localStorage.setItem('user', JSON.stringify(userData));
        //localStorage.setItem("cart", JSON.stringify(response.data.data));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteCartUser(access_token, id) {
    return axios
      .delete(API_URL + 'cart/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        /*let cartData = JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')): [];
        var data = [];
        if(cartData){
          cartData.forEach( (element,n)  => {
            if(element.id != response.data.data.id){
              data[n] = element;
            }
          });
        }

        localStorage.setItem("cart", JSON.stringify(data));*/
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateCartUser(access_token, data) {
    return axios
      .put(API_URL + 'cart', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        /*let cartData = JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')): [];
        var data = [];
        if(cartData){
          cartData.forEach( (element,n)  => {
            if(element.id == response.data.data.id){
              data[n] = response.data.data;
            }else{
              data[n] = element;
            }
          });
        }

        localStorage.setItem("cart", JSON.stringify(data));*/
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postPurchase(access_token, data) {
    return axios
      .post(API_URL + 'purchase/create', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        //localStorage.setItem("cart", JSON.stringify([]));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postCancelPurchase(access_token, data) {
    return axios
      .post(API_URL + 'purchase/cancel', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        //localStorage.setItem("cart", JSON.stringify([]));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  sendPhone(data) {
    return axios
      .post(API_URL + 'signinphone', data, {
        headers: {
          //'Authorization': `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getReferredOrders(access_token, cart_token, id_ambassador) {
    let body = {
      id: id_ambassador,
      token: cart_token,
    };
    return axios
      .post(API_URL + 'purchase/user', body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        //console.log('response.data',response.data,response.data.data)
        //localStorage.setItem("referred_orders", JSON.stringify(response.data.data));

        let userData = JSON.parse(localStorage.getItem('user'));
        //console.log('post localstorage user original',JSON.parse(localStorage.getItem('user')));
        //console.log('post localstorage user',userData);
        //console.log('post response.data',response.data,response.data.data)
        userData.token_order = response.data.token_order;
        //console.log('localstorage user2',userData);
        localStorage.setItem('user', JSON.stringify(userData));

        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postUser(access_token, formData) {
    return axios
      .post(API_URL + 'users', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postAddressUser(access_token, formData) {
    return axios
      .post(API_URL + 'addresses', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserDetails(access_token, id) {
    return axios
      .get(API_URL + 'users', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserAddresses(access_token, id) {
    return axios
      .get(API_URL + 'addresses/ambassador', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserReferreds(access_token, id) {
    return axios
      .get(API_URL + 'users/refered/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deletetUserAddress(access_token, id_ambassador, id) {
    return axios
      .delete(API_URL + 'addresses/ambassador/' + id_ambassador + '/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateAddressUser(access_token, data) {
    return axios
      .put(API_URL + 'addresses/ambassador', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updatePurchase(access_token, data) {
    return axios
      .put(API_URL + 'purchase/checkout ', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updatePurchaseUser(access_token, data) {
    return axios
      .post(API_URL + 'purchase/user/checkout', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPickupWindows(access_token, id, flag) {
    return axios
      .get(API_URL + 'pickup-windows/' + id + '/' + flag, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCategParents(access_token, id) {
    return axios
      .get(API_URL + 'categories/parent/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserHistoryOrders(access_token, id) {
    return axios
      .get(API_URL + 'purchase/history/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserHistoryOrdersReferidos(access_token, id) {
    return axios
      .get(API_URL + 'purchase/history-referidos/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getDistricts(access_token) {
    return axios
      .get(API_URL + 'districts', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateRefered(access_token, data) {
    return axios
      .put(API_URL + 'users/refered', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateUserData(access_token, data) {
    return axios
      .put(API_URL + 'users/data', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateUserPassword(access_token, data) {
    return axios
      .put(API_URL + 'users/password', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deletetUserRefered(access_token, id_ambassador, id) {
    return axios
      .delete(API_URL + 'users/refered/' + id_ambassador + '/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  addAddressData(access_token, data) {
    return axios
      .post(API_URL + 'addresses/temporal', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateAddressData(access_token, data) {
    return axios
      .put(API_URL + 'addresses/temporal', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCheckoutSettings(access_token) {
    return axios
      .get(API_URL + 'settings-checkout', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getBanners(access_token) {
    return axios
      .get(API_URL + 'banners', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  checkOpen(access_token) {
    return axios
      .get(API_URL + 'check-open', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  checkOpenWeb() {
    return axios
      .get(API_URL + 'check-open-web', {})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getSUmDebt(access_token) {
    return axios
      .get(API_URL + 'sum-debt', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getDebts(access_token) {
    return axios
      .get(API_URL + 'debts', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async uploadVoucher(access_token, data) {
    return await axios
      .post(API_URL + 'debts', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async uploadVoucherTemp(access_token, data) {
    return await axios
      .post(API_URL + 'upload-voucher', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  validateCode(access_token, data) {
    return axios
      .post(API_URL + 'validate-code', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getMoreProducts(access_token) {
    return axios
      .get(API_URL + 'more-products', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCountMoreProducts(access_token) {
    return axios
      .get(API_URL + 'count-moreproducts', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

}

export default new ApiService();
